import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useUsersList() {
  // Use toast
  const toast = useToast()
  const showTable = ref(false)
  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'NO',
      sortable: false,
      label: 'NO'
    },
    {
      key: 'createdAt',
      sortable: true,
      label: 'Created at'
    },
    {
      key: 'user',
      sortable: false,
    },
    {
      key: 'email',
      sortable: false,
    },
    {
      key: 'role.name',
      sortable: false,
      label: 'Role',
    },
    {
      key: 'region',
      label: 'Region',
      formatter: title,
      sortable: false,

    },
    {
      key: 'company',
      label: 'Company',
      formatter: title,
      sortable: false,

    },
    {
      key: 'jobTitle',
      label: 'Job Title',
      sortable: false,

    },
    {
      key: 'productConfig',
      label: 'Asset config',
      sortable: false,

    },
    {
      key: 'auditedByPWC',
      label: 'GUP audited',
      sortable: false,
      formatter: title,

    },
    {
      key: 'auditedInMY',
      label: 'Locally audited',
      sortable: false,
      formatter: title,

    },

    { key: 'status', sortable: false },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [25, 50]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const regionFilter = ref(null)
  const roleFilter = ref(null)
  const auditedInMYFilter = ref(null)
  const auditedByPWCFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }
  const timeout = ref(null)
  watch([currentPage, perPage, searchQuery, regionFilter, auditedByPWCFilter, auditedInMYFilter, roleFilter], () => {
    showTable.value = true
         clearTimeout(timeout.value)

    timeout.value = setTimeout(() => {
      refetchData()
      showTable.value = false
    }, 2000)
  })

  const fetchUsers = (ctx, callback) => {
    store
      .dispatch('user/fetchUsers', {
        isActive: true,
        auditedInMY: auditedInMYFilter.value,
        auditedByPWC: auditedByPWCFilter.value,
        region: regionFilter.value,
        roleId: roleFilter.value,
        q: searchQuery.value,
        take: perPage.value,
        page: currentPage.value,
        order: isSortDirDesc.value ? 'DESC' : 'ASC'
      })
      .then(response => {
        const { data: users, meta } = response.body
        callback(users)
        totalUsers.value = meta.itemCount
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }
  const resolveUserAssetConfig = asset => {
    if (asset === 'public') return 'primary'
    if (asset === 'low_touch') return 'warning'
    if (asset === 'high_touch') return 'success'
    return 'primary'
  }
  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status) return 'success'
    return 'danger'
  }

  return {
    timeout,
    showTable,
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    resolveUserAssetConfig,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,
    regionFilter,
    roleFilter,
    auditedByPWCFilter,
    auditedInMYFilter,
    // Extra Filter
  }
}
