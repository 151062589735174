var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"updateUserform"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Role Name","label-for":"roleName"}},[_c('validation-provider',{attrs:{"name":"Role Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"roleName"},model:{value:(_vm.roleData.roleName),callback:function ($$v) {_vm.$set(_vm.roleData, "roleName", $$v)},expression:"roleData.roleName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Description","label-for":"description"}},[_c('validation-provider',{attrs:{"name":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"description"},model:{value:(_vm.roleData.description),callback:function ($$v) {_vm.$set(_vm.roleData, "description", $$v)},expression:"roleData.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Region","label-for":"region"}},[_c('validation-provider',{attrs:{"name":"Region","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.regionNumberOptions,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"region","multiple":""},model:{value:(_vm.roleData.region),callback:function ($$v) {_vm.$set(_vm.roleData, "region", $$v)},expression:"roleData.region"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Permissions","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-card',{staticClass:"border mt-1",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"p-1",model:{value:(_vm.permissionIds),callback:function ($$v) {_vm.permissionIds=$$v},expression:"permissionIds"}},[_c('b-card-title',{staticClass:"font-medium-2"},[_c('feather-icon',{attrs:{"icon":"LockIcon","size":"18"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Permission")])],1)],1),_c('b-table',{staticClass:"mb-0",attrs:{"striped":"","responsive":"","items":_vm.moduleArray},scopedSlots:_vm._u([{key:"cell(module)",fn:function(data){return [_vm._v(" "+_vm._s(data.value)+" ")]}},{key:"cell()",fn:function(data){return [_c('b-form-checkbox',{attrs:{"disabled":!data.value.status,"checked":data.value.value},on:{"change":function($event){return _vm.onCheckModule(data)}}})]}}],null,true)})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs'},on:{"click":_vm.checkValidate}},[_vm._v(" Add ")]),(false)?_c('b-button',{attrs:{"variant":"outline-secondary","type":"reset","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs'}},[_vm._v(" Reset ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }