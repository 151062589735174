<template>
  <div>
    <validation-observer ref="updateUserform">
      <!-- User Info: Input Fields -->
      <b-form>
        <b-row>
          <!-- Field: Role Name -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Role Name"
              label-for="roleName"
            >
              <validation-provider
                #default="{ errors }"
                name="Role Name"
                rules="required"
              >
                <b-form-input
                  id="roleName"
                  v-model="roleData.roleName"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Description -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Description"
              label-for="description"
            >
              <validation-provider
                #default="{ errors }"
                name="Description"
                rules="required"
              >
                <b-form-input
                  id="description"
                  v-model="roleData.description"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Field: Region -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Region"
              label-for="region"
            >
              <validation-provider
                #default="{ errors }"
                name="Region"
                rules="required"
              >
                <v-select
                  v-model="roleData.region"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="regionNumberOptions"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  input-id="region"
                  multiple
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
          >
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Permissions"
                rules="required"
              >
                <!-- PERMISSION TABLE -->
                <b-card
                  no-body
                  class="border mt-1"
                >
                  <b-card-header
                    v-model="permissionIds"
                    class="p-1"
                  >
                    <b-card-title class="font-medium-2">
                      <feather-icon
                        icon="LockIcon"
                        size="18"
                      />
                      <span class="align-middle ml-50">Permission</span>
                    </b-card-title>
                  </b-card-header>
                  <b-table
                    striped
                    responsive
                    class="mb-0"
                    :items="moduleArray"
                  >
                    <template #cell(module)="data">
                      {{ data.value }}
                    </template>
                    <template #cell()="data">
                      <b-form-checkbox
                        :disabled="!data.value.status"
                        :checked="data.value.value"
                        @change="onCheckModule(data)"
                      />
                    </template>
                  </b-table>
                </b-card>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="checkValidate"
    >
      Add
    </b-button>
    <b-button
      v-if="false"
      variant="outline-secondary"
      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      Reset
    </b-button>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { required } from '@validations'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import useUsersList from '@/views/userManager/useUsersList'
import store from '@/store'
import { mapGetters } from 'vuex'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import DateRangePicker from 'vue2-daterange-picker'
import moment from 'moment'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { DatabaseIcon } from 'vue-feather-icons'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
  },

  props: {
    roleData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    ...mapGetters('app', ['regionNumberOptions']),
    ...mapGetters('app', ['answerQuestionOptions']),
    ...mapGetters('app', ['productConfigTypes']),
  },
  setup(props) {
    const permissionIds = ref([])
    const dataFetchRoles = ref([])
    const toast = useToast()
    const { resolveUserRoleVariant } = useUsersList()
    const moduleArray = ref([])
    const fetchPermissions = (ctx, callback) => {
      store
        .dispatch('user/fetchPermissions', {
          take: 10,
          page: 1,
        })
        .then(response => {
          dataFetchRoles.value = response.body
          dataFetchRoles.value.forEach(element => {
            if (
              !moduleArray.value.filter(e => e.module === element.object)
                .length > 0
            ) {
              const objectModule = {
                module: element.object,
                read: {
                  value: false,
                  status: false,
                },
                update: {
                  value: false,
                  status: false,
                },
                create: {
                  value: false,
                  status: false,
                },
                // manage: {
                //   value: false,
                //   status: false,
                // },
                delete: {
                  value: false,
                  status: false,
                },
              }
              moduleArray.value.push(objectModule)
            }
          })

          dataFetchRoles.value.forEach(x => {
            const objIndex = moduleArray.value.findIndex(
              obj => obj.module === x.object,
            )
            if (moduleArray.value[objIndex][`${x.action}`]) {
              moduleArray.value[objIndex][`${x.action}`].status = true

            }
          })
        })
        .catch(err => {
        console.log(err)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching permissions list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
    // const fetchRole = () => {
    //   store
    //     .dispatch('authenticate/fetchRole', props.roleData.roleId)
    //     .then(response => {
    //       const data = response.body.permissions
    //       data.forEach(element => {
    //         if (
    //           !moduleArray.value.filter(e => e.module === element.object)
    //             .length > 0
    //         ) {
    //           const objectModule = {
    //             module: element.object,
    //             read: false,
    //             write: false,
    //             create: false,
    //             delete: false,
    //           }
    //           moduleArray.value.push(objectModule)
    //         }
    //       })
    //       data.forEach(x => {
    //         const objIndex = moduleArray.value.findIndex(
    //           obj => obj.module === x.object,
    //         )
    //         moduleArray.value[objIndex][`${x.code.split('_')[1]}`] = true
    //       })
    //     })
    //     .catch(error => {
    //       // this.$bvtoast({
    //       //   component: ToastificationContent,
    //       //   props: {
    //       //     title: error.response.data.message,
    //       //     icon: 'AlertTriangleIcon',
    //       //     variant: 'danger',
    //       //   },
    //       // })
    //     })
    // }
    const onSubmit = () => {
      const arrID = []
      permissionIds.value.forEach(e => {
        arrID.push(dataFetchRoles.value.find(i => i.object === e.item.module && i.action === e.field.key).id)
      })
      const payload = {
        name: props.roleData.roleName,
        description: props.roleData.description,
        regionIds: props.roleData.region,
        permissionIds: arrID,
        isActive: true,

      }
      store.dispatch('user/addRole', payload)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Add permission success',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          router.push('/user-manager/rbac')
        })
        .catch(e => {
          toast({
            component: ToastificationContent,
            props: {
              title: e.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
    setTimeout(async () => {
      fetchPermissions()
    }, 0)
    return {
      permissionIds,
      router,
      resolveUserRoleVariant,
      moduleArray,
      onSubmit,
      dataFetchRoles,
    }
  },
  methods: {
    onCheckModule(data) {
      const valueData = data.value
      this.moduleArray.find(e => e.module === data.item.module)[data.field.key].value = !valueData.value
      if (this.permissionIds.filter(e => e.item.module === data.item.module && e.field.key === data.field.key).length > 0) {
        this.permissionIds.splice(this.permissionIds.findIndex(e => e.item.module === data.item.module && e.field.key === data.field.key), 1)
      } else {
        this.permissionIds.push(data)
      }

    },
    formatDateDDMMYYYY(time) {
      return moment(time).format('DD/MM/YYYY')
    },
    checkValidate() {
      this.$refs.updateUserform.validate().then(success => {
        if (success) {
          this.onSubmit()
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
